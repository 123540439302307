<template>
    <div id="containerAnimations" class="containerAgenda">
        <div class="enjeu">{{leTeasing}}</div>
        <h2>{{leTitre}}</h2>
        <div class="presentation" v-html="laDescription"></div>
        <div id="Animations" class="propositions">
            <span v-if="!leTabProgramme">La programmation des prochaines sessions de perfectionnement est en cours</span>
            <div v-if="leTabProgramme" class="itemProgramme" v-for="(leEvenement, numEvenement) in leTabProgramme" v-bind:class="{complet : leEvenement.etat == 'COMPLET'}" >
                <span class="icone"></span>
                <a v-if="leEvenement.etat != 'COMPLET'" class="bouton" :href="leEvenement.url" target="_blank">{{leEvenement.libelle_url}}</a>
                <div>
                    <div class="titre gauche">{{leEvenement.titre}}</div>
                    <div v-if="leEvenement.etat == 'COMPLET'" class="complet gauche">COMPLET</div>
                    <div class="sousTitre clear">{{leEvenement.sous_titre}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Animations',
    props: {
            data: {
                type: Object
            }
    },
    data() {
        return {
            tmp:true,
        }
    },
    computed: {
        leTitre() {
            if (this.data && this.data.label)
                return(this.data.label);
            return("Perfectionnement Lorfolio")
        },
        leTeasing() {
            if (this.data && this.data.teasing)
                return(this.data.teasing);
            return("Devenez facilitateur Lorfolio !")
        },
        laDescription() {
            if (this.data && this.data.description)
                return(this.data.description); // description, teasing
            return("Lorfolio peut vous faciliter le travail et enrichir vos missions professionnelles.<br/>Participez en groupe durant une demi-journée à un atelier de présentation des usages de Lorfolio pour vos bénéficiaires.")
        },
        leTabProgramme() {
            if (this.data && this.data.evenements && this.data.evenements.length)
                return(this.data.evenements);
            return(null)
        },
    },
    created() {
        if (this.data) {
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #containerAnimations {
        #Animations {
        }
        .itemProgramme {
            .icone {
                background: url("/img/pro/rencontres/icon_perfectionnement.svg") transparent no-repeat top left;
            }
        }
    }
</style>
