<template>
  <div id="agendaPro">

      <bloc-header2 page="Rencontres OrientEst PRO" />

    <div class="container-agendaPro containerAffichage">

        <div class="agendaPro gauche agendaDecouverte">
            <agenda-decouverte :data="lesDecouvertes"/>
        </div>

        <div class="agendaPro droite agendaAnimation">
            <agenda-animations :data="lesPerfectionnements"/>
        </div>

        <div class="agendaPro droite agendaCafe">
            <agenda-cafes :data="lesCafes"/>
        </div>

        <div class="agendaPro gauche agendaEvenement">
            <agenda-evenement/>
        </div>

        <div class="clear"></div>

    </div>

    <div class="container-agendaPro containerAffichage containerAffichageEcoute">
        <div class="agendaPerso agendaPro">
            <div id="containerPerso" class="containerAgenda">
                <div class="enjeu">Nous vous écoutons</div>
                <h2>Aucune date ne vous convient ?</h2>
                <div class="presentation">
                    Si les dates proposées ne vous correspondent pas, laissez-nous un message avec vos propositions pour une séance ou une session en ligne, ou pour en organiser une chez vous.
                </div>
                <div class="centrer">
                    <router-link :to="{ name: 'ContactPro', params: { pere: laRoute }}" class="bouton">Contacter l'équipe PROS {{lUnivers}}</router-link>
                </div>
            </div>
        </div>
    </div>

    <bloc-footer/>
  </div>
</template>


<script>
import Header2 from '@/components/Header2.vue'
import Footer from '@/components/Footer.vue'

import { addeoGet } from "@/js/datas.js";
import AgendaDecouverte from '@/components/pro/agendas/Decouverte.vue'
import AgendaAnimations from '@/components/pro/agendas/Animations.vue'
import AgendaEvenement from '@/components/pro/agendas/Evenement.vue'
import AgendaCafes from '@/components/pro/agendas/Cafes.vue'

export default {
    name: 'AgendaPro',
    data() {
        return {
            tmp:true,
            ready:false,
            decouvertes:null,
            perfectionnements:null,
            cafes:null,
        }
    },
    props: {
        sitePro: {
            type: Boolean
        },
    },
    components: {
        'bloc-header2': Header2,
        'bloc-footer': Footer,
        'agenda-decouverte': AgendaDecouverte,
        'agenda-animations': AgendaAnimations,
        'agenda-evenement': AgendaEvenement,
        'agenda-cafes': AgendaCafes,
    },
    created() {
        if (this.sitePro) {
            this.$parent.sitePRO = true;
            return addeoGet.getRencontres(this.$store)
              .then(response => {
                if (response && response.data && response.data.length) {
                    this.traiterDataRencontre(response.data);
                    this.ready = true;
                    console.log("created, getRencontres OK")
                }
                else {
                    console.log("created, getRencontres KO")
                }
            })
        }
    },
    computed: {
        laRoute() {
            return(this.$route.name);
        },
        lesDecouvertes() {
            if (this.ready && this.decouvertes) {
                return(this.decouvertes);
                }
            return null;
        },
        lesPerfectionnements() {
            if (this.ready && this.perfectionnements) {
                return(this.perfectionnements);
                }
            return null;
        },
        lesCafes() {
            if (this.ready && this.cafes) {
                return(this.cafes);
                }
            return null;
        },

        markOrientest() {
            return(this.$router.options.markOrientest);
        },
        sitePRO() {
            return(this.$router.options.markOrientest.paramPRO);
        },
        lUnivers() {
            if (this.markOrientest) {
                return("OrientEst");
            }
            else {
                return("Lorfolio");
            }
        },
    },
    methods: {
        traiterDataRencontre(TabDatas) {

            for(let laData of TabDatas) {
                if (laData.code == "decouverte") {
                    this.decouvertes = laData;
                }
                if (laData.code == "perfectionnement") {
                    this.perfectionnements = laData;
                }
                if (laData.code == "cafe") {
                    this.cafes = laData;
                }
            }
        }
    }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

.addeoMedium, .addeoSmall {
    @media screen and (max-width: 800px) {
        #agendaPro {
            .container-agendaPro {
                .agendaPro {
                    width:96%;
                    max-width: 600px;
                    margin:2em auto !important;
                    display:block;
                    float:none !important;
                }
            }
        }
    }
}

    #agendaPro {
        text-align: center;

        .container-agendaPro {
            text-align: center;

            .agendaPro {
                display:inline-block;
                width:41%;
                padding:0;
                text-align: center;
                vertical-align: top;

                .gauche {
                    float:left;
                }

                &.gauche {
                    margin:0 0 1em 7%;
                    float:left;
                }
                &.clear {
                    clear:both;
                }

                &.droite {
                    margin:0 7% 1em 0;
                    float:right;
                    clear: right;
                }

                &:first-of-type {
                    margin-bottom:1em;
                }

                &:last-of-type {
                    margin-top:1em;
                }

                &.agendaPerso {
                    background-color: transparent;
                    width:84%;
                    margin:2em auto;
                    padding-top:4em;
                    display:block;
                }

                .containerAgenda {
                    padding:1em;
                    text-align: left;
                    font-size: 0.9em;

                    .enjeu {
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 0.9em;
                    }
                    h2 {
                        margin:0.8em 0 0.6em;
                        color:#2C3E50;
                    }
                    .propositions {
                        margin:2em 0;
                        /*font-style: italic; 2021/07*/
                        font-weight: 600;

                        &#Animations {
                        }
                    }
                    .presentation {
                        color:#2C3E50;
                        /* white-space:pre-wrap; inutile*/
                    }
                    .consigne {
                        font-size: 0.9em;
                        font-style: italic;
                    }



        .itemProgramme {
            margin-bottom: 1em;
            .icone {
                width:32px;
                height:32px;
                background-size: contain;
                display:block;
                position:absolute;
                margin-top:4px;
            }
            > div {
                margin-left:50px;
                margin-right:100px;
                color:#2C3E50;
                > div {
                    &.titre {
                        font-size: 1.5em;
                        margin-bottom: 0.2em;
                    }
                    &.sousTitre {
                        /*opacity:0.75;*/
                        font-size: 1em;
                        text-transform: capitalize;
                    }
                }
            }
            a.bouton {
                margin-top: 0;
                z-index: 2;
                position: absolute;
                right: 0;
                width:90px;
                max-width: 90px;
                box-sizing: border-box;
            }
            .complet {
                display:none;
                /*
                margin:0.55em 0 0 2em;
                font-size: 0.9em;
                color:black;
                */
                margin:0.35em 0 0 2em;
                font-size: 1.1em;
                font-weight: bold;
            }
            &.complet {
                opacity:0.5;
                div {
                    /*color:#777;*/
                }
                .complet {
                    display:block;
                }
                .bouton {
                    display:none;
                }
            }
        }



                }

                .centrer {
                    text-align: center;
                }
                a.bouton {
                    margin:2em auto 0.4em;
                    display:inline-block;
                    border: 1px solid #356A73;
                    background-color: #356A73;
                    color: rgba(255,255,255,0.8);
                    padding: 0.6em 0.8em;
                    font-size: 0.9em;
                    font-weight: 500;

                    &:hover {
                        /*opacity: 0.5;*/
                        background-color: white;
                        color: #356A73;
                    }
                }
            }

            .agendaDecouverte {
                background-color: #E7EAEC;
            }
            .agendaAnimation {
                background-color: #E7EAEC;
            }
            .agendaEvenement {
                background-color: #444;
                background-color: transparent;
                color:white;
            }
            .agendaCafe {
                background-color: #E7EAEC;
            }

            &.containerAffichageEcoute {
                width: 100%;
                max-width: none;
                height: auto;
                background: no-repeat top #f4ce45 url("~@/assets/img/decoupageAgenda.png");
                text-align: center;
                color: #4b3c8f;
                margin-bottom: 0 !important;
                min-height:350px;
            }
        }
    }

    #footer {
        margin-top: 0;
    }
</style>
